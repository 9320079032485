// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  api: 'https://api-aware.herokuapp.com/v1',
  api_fuxion: 'https://apinew.fuxion.com/api-fuxion',
  production: false,
  mixpanel: { token: '9a8a98eb6ea3a9f185a6db0941bcaad4' },
  firebase: {
    apiKey: 'AIzaSyBJcg4wKaewRenq589SwoA43htwDgv_fGE',
    authDomain: 'aware-tips.firebaseapp.com',
    databaseURL: 'https://aware-tips.firebaseio.com',
    projectId: 'aware-tips',
    storageBucket: 'aware-tips.appspot.com',
    messagingSenderId: '144027612887',
    appId: '1:144027612887:web:0ba0d236f2de7f12b9a292',
    measurementId: 'G-JMNYT7QXVL',
  },
  // url application
  url_base: 'https://www.google.com',
  fbAppId: '217677925992280',
  googleClientID: '144027612887-la8jmfkvh8fnk9i5djcfsgrjtddiqasd.apps.googleusercontent.com',
  version: '0.0.1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import { LetterAvatar } from '../letterAvatar/letterAvatar';

export function isURL(str: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
}
export function startWithHTTP(str: string) {
  return str.substring(0, 4) === 'http';
}
export const MAX_SCORE_VALUE = 180;
export const MIN_SCORE_VALUE = 0;
export const MAX_USER_SCORE_VALUE = 5;
export const MIN_USER_SCORE_VALUE = 0;
export const POWER_USER_SCORE_VALUE = 1;
export function factorUserValue() {
  return (MAX_USER_SCORE_VALUE + POWER_USER_SCORE_VALUE) / MAX_SCORE_VALUE;
}

export function getUserScoreFromValue(value: any) {
  const userScore = value * factorUserValue();
  return getValueInRange(MIN_SCORE_VALUE, MAX_USER_SCORE_VALUE, userScore);
}
export function toSimpleObject(item: any) {
  return JSON.parse(JSON.stringify(item));
}

export function scoreToZone(score: any) {
  return Math.ceil(getUserScoreFromValue(score));
}
export function getParameterByName(name: any, url?: any) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export function getAverageFromLast(
  lastAverage: any,
  newValue: any,
  counter: any
) {
  return (
    ((lastAverage || 0) * (counter || 0) + newValue) / ((counter || 0) + 1)
  );
}
export function getValueInRange(minValue: any, maxValue: any, value: any) {
  return Math.min(maxValue, Math.max(minValue, value));
}

export function getPictureUrlFromFBID(fb_id: any, username?: any) {
  if (fb_id) {
    return `https://graph.facebook.com/${fb_id}/picture?redirect=true&type=large&height=200&width=200`;
  }
  return LetterAvatar(username, 100);
}

export function getPictureUrlFromProfile(user: any) {
  return (
    user.img ||
    (user.fb_id
      ? 'http://graph.facebook.com/' + user.fb_id + '/picture?width=200&height=200'
      : './images/app/iconos/profile-panel.png')
  );
}

export function getLinkVideo(video: any) {
  const localDir = 'http://localhost:8887/';
  // let localDir='http://192.168.1.240:8887/';
  // let localDir='http://192.168.0.34:8887/';
  // let localDir='http://192.168.56.1:8887/';
  if (video && video.video) {
    if (startWithHTTP(video.video)) {
      return video.video;
    } else {
      return localDir + video.video;
    }
  }
  return null;
}

export function fancyTimeFormat(time) {
  // Hours, minutes and seconds
  const hrs = Math.floor(time / 3600);
  const mins = Math.floor((time % 3600) / 60);
  const secs = Math.floor(time % 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '0';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
}

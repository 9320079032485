import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientPreference } from '@app/models/client_preference.model';
import { UserFuxion } from '@app/models/user_fuxion.model';
import { ApiService } from '@arxis/api';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { ModalsService } from '../modals/modals.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class ApiFuxionService {
  // initialize academies
  private _userAuthFuxion = new BehaviorSubject<UserFuxion | null>(null);

  //observable academies
  readonly _userAuthFuxionSelelected = this._userAuthFuxion.asObservable();

  constructor(
    private http: HttpClient,
    private modalService: ModalsService,
    private utilService: UtilsService,
    private apiService: ApiService
  ) {}

  // <<<<<<<<<<<<<<<<<<<<<<<<< User Auth Fuxion Storage >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  changeUserAuthFuxionStorage(userAuthFuxion: UserFuxion) {
    console.log(userAuthFuxion);
    localStorage.setItem('userAuthFuxion', JSON.stringify(userAuthFuxion));
    this._userAuthFuxion.next(userAuthFuxion);
  }

  getUserAuthFuxionStorage() {
    const userFuxion = JSON.parse(localStorage.getItem('userAuthFuxion'));

    if (!this._userAuthFuxion.getValue() && userFuxion) {
      this.changeUserAuthFuxionStorage(userFuxion);
      return;
    }

    return this._userAuthFuxion.getValue();
  }

  removeUserAuthFuxionStorage() {
    this._userAuthFuxion.next(null);
    localStorage.removeItem('userAuthFuxion');
  }

  // <<<<<<<<<<<<<<<<<<<<<<<<< functions api fuxion >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  async loginFuxion(username, password): Promise<UserFuxion> {
    // Initialize Params Object
    const Params = {
      username: username,
      password: password,
    };

    // http request
    return await new Promise<UserFuxion>((resolve, reject) => {
      this.apiService
        .post('user/associate/', Params)
        .toPromise()
        .then(
          (response: any) => {
            // Success
            // console.log(response);
            if (response.success) {
              resolve(response['data']);
            } else {
              reject(response);
            }
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async getPriceFuxion(products, country): Promise<any> {
    // Initialize Params Object
    const Params = {
      products: [123, 23],
      country: 'PE',
    };

    // http request
    return await new Promise<any>((resolve, reject) => {
      this.apiService
        .post('db/Maestro/GetPrice', Params)
        .toPromise()
        .then(
          (response) => {
            // Success
            // console.log(response);
            resolve(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async getListPreferentClient(): Promise<ClientPreference[]> {
    // http request
    return await new Promise<ClientPreference[]>((resolve, reject) => {
      this.apiService
        .get('user/clients/')
        .toPromise()
        .then(
          (response) => {
            // Success
            // console.log(response);
            resolve(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async getPowerLink(data: { customerId: any; country: string }, products: string): Promise<string> {
    // Initialize Params Object
    const Params = {
      customer_id: data.customerId,
      country: data.country?.replace('USA', 'US'),
      products: products,
    };

    // http request
    return await new Promise<string>((resolve, reject) => {
      this.apiService
        .post('power_link', Params)
        .toPromise()
        .then(
          (response) => {
            // Success
            // console.log(response);
            resolve(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async getLinkPreferentClient(clientPreference: ClientPreference, products: string): Promise<string> {
    // Initialize Params Object
    const Params = {
      customer_id: clientPreference.customerID,
      country: clientPreference.maincountry?.replace('USA', 'US'),
      products,
    };

    // http request
    return await new Promise<string>((resolve, reject) => {
      this.apiService
        .post('preferent_link', Params)
        .toPromise()
        .then(
          (response) => {
            // Success
            // console.log(response);
            resolve(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }
}

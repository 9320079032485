import { Component, OnInit, Input } from '@angular/core';
import { AlertController, PopoverController, NavController, ToastController } from '@ionic/angular';
import { CatalogueService } from '@app/providers/catalogue/catalogue.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedSocialComponent } from '../shared-social/shared-social.component';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  // number
  @Input() idItem: number;

  // any
  @Input() subItems: any[];
  data = undefined;

  // string
  @Input() code: string = '';
  name: string = '';
  linkProduct: string = '';
  linkTitle = '';

  toast;

  // boolean
  @Input() title: boolean;
  private deleteFavorite: boolean = false;
  rename: boolean = false;
  shared: boolean = false;

  constructor(private alertController: AlertController,
    private popoverController: PopoverController,
    private catalogueService: CatalogueService,
    private navCtrl: NavController,
    private toastController: ToastController,
    private spinnerService: NgxSpinnerService,

  ) {
  }

  ngOnInit() {

    if (this.title) {
      this.linkTitle = '';
      this.linkTitle = this.getLink();

    } else {
      this.linkProduct = '';
      this.linkProduct = location.protocol + '//' + location.host + '/catalogue/product/detail/' + this.code;

    }
  }

  async presentAlertConfirm() {

    const alert = await this.alertController.create({
      // cssClass: 'my-custom-class',
      // header: 'Confirm!',
      message: '<strong class="ion-text-center">¿Esta seguro que desea eliminarlo?</strong>',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: async (blah) => {
            await this.ondDissmis();
          }
        }, {
          text: 'Ok',
          handler: async () => {
            // open spinner
            this.spinnerService.show();
            // call function
            await this.deleteProductFavorite();
            await this.ondDissmis();
          }
        }
      ]
    });

    await alert.present();
  }

  async ondDissmis() {
    await this.popoverController.dismiss(
      {
        delete: this.deleteFavorite
      }
    );
  }

  async deleteProductFavorite() {

    if (this.title) {

      try {
        this.spinnerService.show();
        let response = await this.catalogueService.deleteGroupFavorite(this.idItem);
        this.deleteFavorite = true;

        // dissmis popover
        await this.ondDissmis();

      } catch (error) {
        console.log(error);

        // spinner stop
        this.spinnerService.hide();

        // dissmis popover
        await this.ondDissmis();
      }

    } else {

      try {

        let response = await this.catalogueService.deleteOneItemFavorite(this.subItems[0].id, this.idItem);
        this.deleteFavorite = true;
        // spinner stop
        this.spinnerService.hide();
        // dissmis popover
        await this.ondDissmis();

      } catch (error) {
        console.log(error);
        // spinner stop
        this.spinnerService.hide();
        await this.ondDissmis();
      }

    }
  }

  async visualizeProduct() {
    let codes = '';

    // open spinner
    this.spinnerService.show();

    for (const item of this.subItems) {
      if (codes === '') {
        codes = codes + item.code;
      } else {
        codes = codes + ',' + item.code;
      }
    }

    // spinner stop
    this.spinnerService.hide();
    await this.ondDissmis();
    this.navCtrl.navigateForward('/catalogue/product/detail/' + codes);
  }

  async updateName(text?: string) {

    if (this.name !== '') {
      // open spinner
      this.spinnerService.show();

      this.data = {
        name: this.name,
        idItem: this.idItem
      }

      try {

        let response = await this.catalogueService.changeName(this.name, this.idItem);

        if (text) {
          this.rename = false;
          // spinner stop
          this.spinnerService.hide();
          this.shared = true;

        } else {
          // spinner stop
          this.spinnerService.hide();
          await this.popoverController.dismiss(this.data);

        }

      } catch (error) {
        console.log(error);
        // spinner stop
        this.spinnerService.hide();
        await this.ondDissmis();
      }

    } else {

      if (this.toast) {
        this.toast.dismiss();
      }

      this.toast = await this.toastController.create({
        message: 'Por favor ingrese un nombre',
        duration: 2000
      });

      this.toast.present();
    }
  }

  async onDissmis() {

    if (this.data) {
      await this.popoverController.dismiss(this.data);

    } else {
      await this.popoverController.dismiss();
    }
  }

  getLink() {
    let codes = '';

    for (const item of this.subItems) {
      if (codes === '') {
        codes = location.protocol + '//' + location.host + '/catalogue/product/detail/' + item.code;
      } else {
        codes = codes + ',' + item.code;
      }
    }

    return codes;
  }
}

import { Injectable, Inject} from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Subject } from 'rxjs';

@Injectable()
export class PlatformAware {
  mixpanel: any;
  isReady: boolean;
  distinct_id: Subject<string> = new Subject();
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {

  }

  isBrowser() {
      return isPlatformBrowser(this.platformId);
  }

  isServer() {
    return isPlatformServer(this.platformId);
  }
}

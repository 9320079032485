import { Injectable } from '@angular/core';
import { Product } from '@app/models/product.model';
import { ApiService } from '@arxis/api';
import { map } from 'rxjs/operators';
import { ModalsService } from '../modals/modals.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogueService {
  constructor(private apiService: ApiService, private modalService: ModalsService, private utilService: UtilsService) {}

  // <========================== Catalogue ==========================================>
  async getCatalogueFavorites() {
    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .get('favorite/product', {})
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async getCatalogueProduct(q: string): Promise<Product> {
    //  Initialize Params Object
    const Params = {
      q: q,
    };

    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .get('products/list/', Params)
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data'][0]);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);
          }
        );
    });
  }

  async getFuxionPriceProduct(
    fuxionCode: number | string,
    country: string
  ): Promise<{
    itemCode: string;
    priceWithoutTax: number;
    priceWithTax: number;
    id: number;
    price: string;
    code: string;
  }> {
    return await this.apiService
      .post('products/list', {
        products: [fuxionCode],
        country,
      })
      .pipe(
        map((response: any) => {
          if (response.success) {
            return response.data[0];
          } else {
            return null;
          }
        })
      )
      .toPromise();
  }

  async getCatalogue(country: string) {
    //  Initialize Params Object
    const Params = {
      country: country,
    };

    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .get('category/list/', Params)
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  // <========================== Favorites ==========================================>

  async changeName(name: string, id: number) {
    //  Initialize Params Object
    const Params = {
      name: name,
    };

    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .put('favorite/' + id, Params)
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async addFavorite(name: string, ids: number[]) {
    //  Initialize Params Object
    const Params = {
      title: name,
      prod: ids,
    };

    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .post('favorite/product', Params)
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async deleteGroupFavorite(id: number) {
    //  Initialize Params Object
    const options = {
      body: {
        id: id,
      },
    };

    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .delete('favorite/product/', options)
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async deleteOneItemFavorite(prod_id: number, id: number) {
    //  Initialize Params Object

    const options = {
      body: {
        prod_id: prod_id,
      },
    };

    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .delete('favorite/' + id + '/product', options)
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }
}

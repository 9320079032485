import { Injectable } from '@angular/core';
import { PlatformAware } from '../platform/platform';
import * as _ from 'lodash';
import { CookiesService } from '../cookies/cookies.service';

@Injectable()
export class LocalStorageAware {
  prefix = 'ngStorage-';
  constructor(private platform: PlatformAware, private cookies: CookiesService) {}

  get country() {
    return this.getItem('country');
  }
  set country(value) {
    this.setItem('country', value);
  }

  get lastAccess() {
    return this.getItem('lastAccess');
  }
  set lastAccess(value) {
    this.setItem('lastAccess', value);
  }

  get guestSongs() {
    return this.getItem('guestSongs');
  }
  set guestSongs(value) {
    this.setItem('guestSongs', value);
  }

  get loggedSongs() {
    return this.getItem('loggedSongs');
  }
  set loggedSongs(value) {
    this.setItem('loggedSongs', value);
  }

  get mailSended() {
    return this.getItem('mailSended');
  }

  set mailSended(value) {
    this.setItem('mailSended', value);
  }

  get tokenUser() {
    return this.getItem('tokenUser');
  }
  set tokenUser(value) {
    this.setItem('tokenUser', value);
  }

  get facebook_id() {
    return this.getItem('facebook_id');
  }

  set facebook_id(value) {
    this.setItem('facebook_id', value);
  }

  get google_id() {
    return this.getItem('google_id');
  }

  set google_id(value) {
    this.setItem('google_id', value);
  }
  get user_id() {
    return this.getItem('user_id');
  }
  set user_id(value) {
    this.setItem('user_id', value);
  }

  get session() {
    let session = this.getItem('session');
    try {
      if (typeof session === 'string') {
        return JSON.parse(session);
      }
    } catch (e) {
      session = undefined;
    }
    return session;
  }
  set session(value) {
    this.setItem('session', value);
  }

  get finnishTimer() {
    return this.getItem('finnishTimer');
  }
  set finnishTimer(value) {
    this.setItem('finnishTimer', value);
  }

  get emailUsed() {
    return this.getItem('emailUsed');
  }
  set emailUsed(value) {
    this.setItem('emailUsed', value);
  }

  get songsPlayed() {
    return this.getItem('songsPlayed');
  }

  set songsPlayed(value) {
    this.setItem('songsPlayed', value);
  }

  get sungSongs() {
    return this.getItem('sungSongs');
  }

  set sungSongs(value) {
    this.setItem('sungSongs', value);
  }

  get songsWithTones() {
    return this.getItem('songsWithTones');
  }
  set songsWithTones(value) {
    this.setItem('songsWithTones', value);
  }

  get sendedEmail() {
    return this.getItem('sendedEmail');
  }
  set sendedEmail(value) {
    this.setItem('sendedEmail', value);
  }

  get firtsPreRoll() {
    return this.getItem('firtsPreRoll');
  }

  set firtsPreRoll(value) {
    this.setItem('firtsPreRoll', value);
  }

  get preRollSeen() {
    return this.getItem('preRollSeen');
  }

  set preRollSeen(value) {
    this.setItem('preRollSeen', value);
  }

  get daysWhenSendedMail(): Array<any> {
    const a: any = Array.from(JSON.parse(this.getItem('daysWhenSendedMail')));
    if (a) {
      a.originalPush = a.push;
      a.push = (...items): number => {
        a.originalPush(...items);
        this.daysWhenSendedMail = a;
        return a.length;
      };
      return a;
    }
    return [];
  }

  set daysWhenSendedMail(value) {
    this.setItem('daysWhenSendedMail', JSON.stringify(value));
  }

  get surveysViews() {
    return this.getItem('surveysViews');
  }
  set surveysViews(value) {
    this.setItem('surveysViews', value);
  }

  get planPaylinkModal() {
    return this.getItem('planPaylinkModal');
  }

  set planPaylinkModal(value) {
    this.setItem('planPaylinkModal', value);
  }
  
  getItem(key: string) {
    if (this.platform.isBrowser()) {
      try {
        return JSON.parse(this.cookies.get(key)) || localStorage.getItem(key);
      } catch (e) {
        return this.cookies.get(key) || localStorage.getItem(key);
      }
    } else {
      try {
        return JSON.parse(this.cookies.get(key));
      } catch (e) {
        return this.cookies.get(key);
      }
    }
  }

  setItem(key: string, value: any) {
    if (this.platform.isBrowser()) {
      localStorage.setItem(key, value);
      return this.cookies.set(key, value);
    } else {
      return this.cookies.set(key, value);
    }
  }

  removeItem(key: string) {
    if (this.platform.isBrowser()) {
      this.cookies.delete(key);
      localStorage.removeItem(key);
      localStorage.removeItem(this.prefix + key);
    } else {
      this.cookies.delete(key);
    }
  }

  removeAll() {
    if (this.platform.isBrowser()) {
      this.cookies.deleteAll();
      localStorage.clear();
    } else {
      this.cookies.deleteAll();
    }
  }
}

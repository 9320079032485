import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Academy } from '@app/models/academy.model';
import { Tools } from '@app/models/tool.model';
import * as _ from 'lodash';
import { Chapter, Course } from '@app/models/course.model';

@Injectable({
  providedIn: 'root'
})
export class ObservablesService {

  // initialize academies
  private _academies = new BehaviorSubject<Academy[] | null>([]);

  //observable academies
  readonly _academiesSelelected = this._academies.asObservable();

  // initialize chapter
  private _chapter = new BehaviorSubject<Chapter | null>(null);

  //observable chapter
  readonly _chapterSelelected = this._chapter.asObservable();

  // initialize chapters
  private _chapters = new BehaviorSubject<Chapter[] | null>(null);

  //observable chapters
  readonly _chaptersSelelected = this._chapters.asObservable();

  // initialize open Menu
  private _openMenu = new BehaviorSubject<boolean | null>(false);

  //observable open Menu
  readonly _openMenuSelelected = this._openMenu.asObservable();

  // initialize not Autorize
  private _notAutorize = new BehaviorSubject<boolean | null>(false);

  //observable platform Phone
  readonly _notAutorizeSelelected = this._notAutorize.asObservable();

  // initialize play Video
  private _playVideo = new BehaviorSubject<Tools | null>(null);

  //observable play Video
  readonly _playVideoSelelected = this._playVideo.asObservable();

  // initialize platform Phone
  private _academiesUpdate = new BehaviorSubject<number | null>(-1);

  //observable platform Phone
  readonly _academiesUpdateSelelected = this._academiesUpdate.asObservable();

  constructor() { }

  // <<<<<<<<<<<<<<<<<<<<<<<<< Academies >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  changeAcademiesStorage(academies: Academy[]) {
    // console.log(academies);

    this._academies.next(academies);
  }

  getAcademiesStorage() {
    return this._academies.getValue();
  }

  removeAcademiesStorage() {
    this._academies.next(null);
  }

  // <<<<<<<<<<<<<<<<<<<<<<<<< Academies Update>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  changeAcademiesUpdateStorage(academiesUpdate: number) {
    this._academiesUpdate.next(academiesUpdate);
  }

  getAcademiesUpdateStorage() {
    return this._academiesUpdate.getValue();
  }

  removeAcademiesUpdateStorage() {
    this._academiesUpdate.next(null);
  }

  // <<<<<<<<<<<<<<<<<<<<<<<<< chapter >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  changeChapterStorage(chapter: Chapter) {
    // console.log(academies);

    this._chapter.next(chapter);
  }

  getChapterStorage() {
    return this._chapter.getValue();
  }

  removeChapterStorage() {
    this._chapter.next(null);
  }

  // <<<<<<<<<<<<<<<<<<<<<<<<< chapters >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  changeChaptersStorage(chapter: Chapter[]) {
    // console.log(academies);

    this._chapters.next(chapter);
  }

  getChaptersStorage() {
    return this._chapters.getValue();
  }

  removeChaptersStorage() {
    this._chapters.next(null);
  }

  // <<<<<<<<<<<<<<<<<<<<<<<<< open Menu >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  changeOpenMenuStorage(openMenu: boolean) {
    this._openMenu.next(openMenu);
  }

  getOpenMenuStorage() {
    return this._openMenu.getValue();
  }

  removeOpenMenuStorage() {
    this._openMenu.next(null);
  }

  // <<<<<<<<<<<<<<<<<<<<<<<<< not autorize >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  changeNotAutorizeStorage(notAutorize: boolean) {
    this._notAutorize.next(notAutorize);
  }

  getNotAutorizeStorage() {
    return this._notAutorize.getValue();
  }

  removeNotAutorizeStorage() {
    this._notAutorize.next(null);
  }

  // <<<<<<<<<<<<<<<<<<<<<<<<< play Video >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  changePlayVideoStorage(playVideo: Tools) {
    this._playVideo.next(playVideo);
  }

  getPlayVideoStorage() {
    return this._playVideo.getValue();
  }

  removePlayVideoStorage() {
    this._playVideo.next(null);
  }


  // <<<<<<<<<<<<<<<<<<<<<<<<< additional functions >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  clearBehavior() {
    this.removeAcademiesStorage();
  }

  calculateProgress(tasks: any[], id?: number) {
    let task_total = 0;
    let completed = 0;
    let progress = 0;
    let chapter = this._chapter.getValue();

    //for (const academy of this._academies.getValue()) {

    //if (academy.id == id) {
    task_total = chapter.total_tasks;

    for (const task of tasks) {
      if (task.complete) {
        completed = completed + 1;
      }
    }
    // }
    //}

    progress = completed / task_total;

    return progress;

  }

  async getNameChapter(id: number) {
    for (const academy of this._academies.getValue()) {
      if (academy.id == id) {
        return academy.title;
      }
    }
  }

  getNextTask(id) {
    for (const academy of this._academies.getValue()) {
      if (academy.completed != academy.total_tasks) {
        return academy.id;
      }
    }

    return null;
  }

  getNextChapter() {
    for (const chapter of this._chapters.getValue()) {
      if (chapter.completed != chapter.total_tasks) {
        return chapter;
      }
    }

    return null;
  }

  completeTaskAcademy(id: number) {

    let academies = _.clone(this._academies.getValue())
    for (const academy of academies) {
      if (academy.id == id) {
        academy.completed = academy.completed + 1;
        this.changeAcademiesStorage(academies);
        this.changeAcademiesUpdateStorage(id);
        return;
      }
    }
  }

  completeTaskChapter(id: number) {

    let chapters = _.clone(this._chapters.getValue());
    
    for (const chapter of chapters) {
      if (chapter.id == id) {
        chapter.completed = chapter.completed + 1;
        this.changeChapterStorage(chapter);
        this.changeChaptersStorage(chapters);
        return;
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { ApiService } from '@arxis/api';
import { ModalsService } from '../modals/modals.service';
import { CountryInterface } from '@app/models/country.model';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  // any
  loading: HTMLIonLoadingElement;

  constructor(
    private loadingController: LoadingController,
    private apiService: ApiService,
    private modalService: ModalsService,
    public toastController: ToastController
  ) {}

  // Initialize loading
  async presentLoading(message) {
    this.loading = await this.loadingController.create({
      message,
      // duration: 19000,
    });
    await this.loading.present();
  }

  // Dismiss loading
  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  async toast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
    });
    toast.present();
  }

  // <================================= http api ==============================>

  async getCountries(): Promise<CountryInterface[]> {
    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .get('country', {})
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }
}

<ion-content class="name">
  <ion-grid>
    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col class="ion-no-padding ion-no-margin col-button">
        <ion-item>
          <ion-input type="text" [(ngModel)]="name" placeholder="Nombre del Favorito"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col class="ion-no-padding ion-no-margin ion-text-center">
        <ion-button color="primary" (click)="dissmis()">Guardar</ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col class="ion-no-padding ion-no-margin ion-text-center">
        <ion-button color="primary" (click)="dissmis('shared')">Guardar y Compartir</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
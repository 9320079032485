<!-- Init ion-app -->
<ion-app>
  <!-- background web online -->
  <div class="background-content"></div>

  <!-- Init menu -->
  <ion-menu (ionWillClose)="closeMenu()" side="start" menuId="first" contentId="main" [disabled]="disable_menu">
    <!-- Init header -->
    <ion-header>
      <!-- Init grid -->
      <ion-grid>
        <ion-row>
          <!-- Init image -->
          <ion-col class="ion-text-center col_header" size="4">
            <ion-toolbar>
              <img class="foto_login" [src]="
                  user?.img
                    ? user?.img.includes('./images')
                      ? user?.img.replace('.', './assets')
                      : user?.img
                    : './assets/icon-user.png'
                " alt="" />
            </ion-toolbar>
          </ion-col>
          <!-- End image -->

          <ion-col class="col_header" size="8">
            <!-- Init message welcome -->
            <ion-item class="information_message" lines="none">
              <ion-label class="ion-no-padding ion-no-margin">Bienvenido/a</ion-label>
            </ion-item>
            <!-- End message welcome -->

            <!-- Init information user -->
            <ion-item class="information_user" lines="none" *ngIf="user">
              <ion-icon class="ion-no-padding ion-no-margin" color="danger" slot="start" name="person-circle-outline">
              </ion-icon>
              <ion-label class="ion-no-padding ion-no-margin text_header">{{
                user?.full_name || user?.first_name + ' ' + user?.last_name
              }}</ion-label>
            </ion-item>
            <!-- End information user -->
          </ion-col>
        </ion-row>

        <!-- Init routerLink -->
        <ion-row class="ion-text-center href_header">
          <ion-col>
            <ion-menu-toggle>
              <ion-label>
                <a [routerLink]="['/profile']" routerDirection="root" class="text_menu profile">Ver Perfil</a> |
                <a class="text_menu logout" (click)="logout()">Cerrar Sesión</a>
              </ion-label>
            </ion-menu-toggle>
          </ion-col>
        </ion-row>
        <!-- End routerLink -->
      </ion-grid>
      <!-- End grid -->
    </ion-header>
    <!-- End header -->

    <!-- Init content -->
    <ion-content>
      <!-- Init grid -->
      <ion-grid>
        <!-- Init list -->

        <ion-item>
          <!-- search -->
          <ion-searchbar (ionChange)="filterMenu($event)" class="ion-no-padding ion-no-margin search"
            placeholder="Buscar"></ion-searchbar>
        </ion-item>

        <div (click)="expandItem(item)" *ngFor="let item of items; let i = index">
          <!-- Init menu type link -->
          <ion-menu-toggle class="menu_toggle_menu" [routerLink]="[item.url]" [queryParams]="item.queryParams" routerDirection="root"
            *ngIf="item.array.length === 0">
            <ion-item (click)="this.option_menu = item.menu">
              <!-- title -->
              <a class="ion-no-padding ion-no-margin">
                <ion-card-title class="text_ios" [ngClass]="{ active_menu: item.menu === this.option_menu }">
                  {{ item.title }}</ion-card-title>
              </a>
            </ion-item>
          </ion-menu-toggle>
          <!-- End menu type link -->

          <!-- Init title menu-->
          <ion-item class="aware-menu-item" lines="full" *ngIf="item.array.length !== 0">
            <!-- Init menu type sub-menu -->
            <!-- icon -->
            <ion-icon class="icon_ios" slot="end" name="chevron-down-outline" *ngIf="!item.expanded"> </ion-icon>
            <ion-icon class="icon_ios" slot="end" name="chevron-up-outline" *ngIf="item.expanded"></ion-icon>

            <!-- title -->
            <ion-card-title class="text_ios" [ngClass]="{ active_menu: item.menu === this.option_menu }">
              {{ item.title }}</ion-card-title>
            <!-- End menu type sub-menu -->
          </ion-item>
          <!-- End title menu -->

          <!-- Init sub-menu -->
          <ion-row class="ion-no-padding ion-no-margin" [hidden]="!(item.array != [])">
            <ion-col size="12" class="ion-no-padding ion-no-margin">
              <app-expandable expandHeight="500px" [expanded]="item.expanded">
                <ion-list class="row_sub_menu">
                  <ng-container *ngIf="item.menu !== 'academia'">
                    <ion-menu-toggle>
                      <ion-item lines="none" *ngFor="let subItem of item.array"
                        (click)="this.option_menu = item.menu; this.option_subMenu = subItem.sub_menu"
                        [routerLink]="['/' + subItem.url]" routerDirection="root">
                        <!-- init routeLink sub-menu -->
                        <a routerDirection="root" class="ion-text-center sub-menu"
                         [ngClass]="{ 'active_sub-menu': subItem.sub_menu === this.option_subMenu }">
                          <ion-label class="text_menu"
                            [ngClass]="{ active_title_menu: subItem.sub_menu === this.option_subMenu }">
                            {{ subItem.name }}
                          </ion-label>
                        </a>
                        <!-- End routeLink sub-menu -->
                      </ion-item>
                    </ion-menu-toggle>
                  </ng-container>

                  <!-- <ng-container *ngIf="item.menu === 'academia'">
                    <ion-menu-toggle>
                      <ion-item lines="none" *ngFor="let academy of academies"
                        (click)="this.option_menu = item.menu; this.option_subMenu = academy.title"
                        [routerLink]="['/' + academy.url + '/task']" routerDirection="root">
                        <a class="ion-text-center sub-menu-academy"
                          [ngClass]="{ 'active_sub-menu-academies': academy.sub_menu == this.option_subMenu }">
                          <ion-item class="item-academy" lines="none">
                            <ion-label class="text_menu"
                              [ngClass]="{ active_title_menu: academy.sub_menu == this.option_subMenu }">
                              {{ academy.title }}
                            </ion-label>
                            <ion-badge color="success" *ngIf="academy.completed == academy.total_tasks">
                              {{ academy.completed }}/{{ academy.total_tasks }}
                            </ion-badge>
                            <ion-badge color="secondary" *ngIf="academy.completed < academy.total_tasks">
                              {{ academy.completed }}/{{ academy.total_tasks }}
                            </ion-badge>
                          </ion-item>
                        </a>
                      </ion-item>
                    </ion-menu-toggle>
                  </ng-container> -->
                </ion-list>
              </app-expandable>
            </ion-col>
          </ion-row>
          <!-- End sub-menu -->
        </div>
        <!-- End list -->
      </ion-grid>
      <!-- End grid -->
    </ion-content>
    <!-- Init content -->
  </ion-menu>
  <!-- End menu -->

  <!-- Init routes -->
  <ion-router-outlet (activate)="current_route()" id="main">

    <!-- init spinner -->
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
      <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
    <!-- end spinner -->

  </ion-router-outlet>
  <!-- End routes -->
</ion-app>
<!-- End ion-app -->

<app-loading *ngIf="isOnTransition"></app-loading>
import { Injectable, Injector } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { PlatformAware } from '../platform/platform';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class LocationService {
  doc;
  host;
  constructor(
    private injector: Injector,
    private location: Location,
    private platform: PlatformAware
  ) {
    this.doc = this.injector.get(DOCUMENT);
    this.host = this.platform.isServer()
      ? this.injector.get(REQUEST).headers['host']
      : this.doc.location.protocol + '//' + this.doc.location.hostname;
  }

  get currentUrl(): string {
    const url_string = this.host + this.location.path();
    return url_string;
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(this.currentUrl);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
}

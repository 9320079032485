import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Platform } from '@angular/cdk/platform';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// plugins
import { CustomFormsModule } from 'ngx-custom-validators';
import { ComponentsModule } from './components/components.module';
import { ApiService, API_ENDPOINT_CONFIG } from '@arxis/api';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '@env/environment';
import { CountryInitializerFactory, CountryInitializer } from './providers/country/country.initializer';
import { TokenInterceptor } from './providers/api/token.interceptor';
import { CountryService } from './providers/country/country';
import { Mxp } from './providers/mxp/mxp';
import { PlatformAware } from './providers/platform/platform';
import { LocalStorageAware } from './providers/local-storage/local-storage';
import { CookiesService } from './providers/cookies/cookies.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { LocationService } from './providers/helpers/location';
import { User } from './providers/user/user';
import { SessionService } from './providers/user/session.service';
import { ShareModule } from '@ngx-share/core';
import { AuthTokenFactory, AuthTokenService } from './providers/user/auth-token.service';
import { LoadingModule } from './components/loading/loading.module';

// Import library ngx-spinner
import { NgxSpinnerModule } from "ngx-spinner";
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';

export function getRequest(): any {
  return { headers: { cookie: document.cookie } };
}
@NgModule({
  declarations: [AppComponent],

  entryComponents: [],

  imports: [
    BrowserModule,
    // IonicModule.forRoot({mode: 'md'}),
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomFormsModule,
    ComponentsModule,
    HttpClientModule,
    ShareModule,
    LoadingModule,
    NgxSpinnerModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule
    
  ],

  providers: [
    Platform,
    StatusBar,
    SplashScreen,
    ApiService,
    CountryService,
    CountryInitializer,
    Mxp,
    PlatformAware,
    LocalStorageAware,
    CookiesService,
    LocationService,
    AuthTokenService,
    SessionService,
    User,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      // The server provides these in main.server
      provide: REQUEST,
      useFactory: getRequest
    },
    {
      provide: API_ENDPOINT_CONFIG,
      useValue: { url: environment.api },
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: CountryInitializerFactory,
    //   deps: [CountryInitializer],
    //   multi: true,
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: AuthTokenFactory,
      deps: [AuthTokenService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorInterceptor,
    //   multi: true
    // },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-shared-social',
  templateUrl: './shared-social.component.html',
  styleUrls: ['./shared-social.component.scss'],
})
export class SharedSocialComponent implements OnInit {

  // string
  @Input() link = '';

  constructor(private spinnerService: NgxSpinnerService) { }

  ngOnInit() {
    // spinner stop
    this.spinnerService.hide();
  }

  onDissmis(){
    
  }

}

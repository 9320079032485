import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { User } from '../user/user';
import { environment } from '@env/environment';
import { CountryService } from '../country/country';
import { LocalStorageAware } from '../local-storage/local-storage';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private country: CountryService, private localStorage: LocalStorageAware, public user: User) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.search(environment.api) === 0) {
      // attach tcken
      return this.handleApiRequest(request, next);
    } else {
      return next.handle(request);
    }
  }

  handleApiRequest(request, next) {

    const tokenUser = this.user.currentUser ? this.user.currentUser.token : localStorage.getItem('tokenUser');
    // let country = this.country.currentCountry;
    const country = this.user.currentUser ? this.user.currentUser.country : localStorage.getItem('country');

    // este token solo es temporal
    // tokenUser = '4d3300e400cba44915fc4a31de76c7227212b0c9';

    // este pais tambien va temporal
    // const country = 'PE';

    const setHeaders: any = {};

    if (tokenUser) {
      setHeaders.Authorization = `token ${tokenUser}`;
    }
    // if (this.country.currentCountry) {
    //   setHeaders.country = country;
    // }
    // setHeaders.platform="mobile";
    // setHeaders.device="android"

    request = request.clone({
      setHeaders,
    });

    const handler = next.handle(request).pipe(
      catchError((error, caught) => {
        if (error.status === 401 || error.status === 403) {
          // this.store.dispatch(new fromAuth.LogoutAction());
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );

    return handler;
  }
}

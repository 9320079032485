import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiService } from '@arxis/api';
import { Observable, Subject, BehaviorSubject, of, throwError } from 'rxjs';
import { Mxp } from '../mxp/mxp';
import { UserSessionResponseInterface } from '@interfaces/interfaces';
// import { Notifications } from '../notifications/notifications';
import { LocalStorageAware } from '../local-storage/local-storage';
import { map, share, catchError, switchMap, tap } from 'rxjs/operators';
import { PlatformAware } from '../platform/platform';
import { SessionService } from './session.service';
import { KsResponse } from '@interfaces/ks-response.interface';
import { CountryService } from '@providers/country/country';
/**
 * Most apps have the concept of a User. This is a simple provider
 * with stubs for login/signup/etc.
 *
 * This User provider makes calls to our API at the `login` and `signup` endpoints.
 *
 * By default, it expects `login` and `signup` to return a JSON object of the shape:
 *
 * ```json
 * {
 *   status: 'success',
 *   user: {
 *     // User fields your app needs, like 'id', 'name', 'email', etc.
 *   }
 * }
 * ```
 *
 * If the `status` field is not `success`, then an error is detected and returned.
 */
@Injectable()
export class User {
  _user: UserSessionResponseInterface;
  public user$: BehaviorSubject<UserSessionResponseInterface> = new BehaviorSubject(undefined);
  // sessionDeleteSub: Subscription;
  localSession: any;
  readyState: BehaviorSubject<boolean> = new BehaviorSubject(false);
  newly = false;

  userLoginOK: Subject<any> = new Subject();
  keepAliveInterval;
  sessionError: boolean;
  messages: any;
  IdPlaylistFavorite = 90;
  goRootSamsung: Subject<any> = new Subject();

  constructor(
    private country: CountryService,

    public http: HttpClient,
    public api: ApiService,

    public mixpanel: Mxp,
    private localStorage: LocalStorageAware,
    private platform: PlatformAware
  ) {}

  doReload() {
    document.write('');
    location.reload();
  }

  onRetry(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      (<any>window).doReload();
      return false;
    }
    document.getElementById('retry-btn').focus();
    return false;
  }

  setViewPayment() {
    this.api
      .post('send/notification/', {
        token: this.getLocalSession().token,
      })
      .subscribe((res: any) => {
        console.log('res', res);
      });
  }

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this._user !== null && this._user !== undefined;
  }

  // Returns current user
  get currentUser(): UserSessionResponseInterface {
    return this.authenticated ? this._user : null;
  }

  get premium() {
    return this.currentUser && this.currentUser.premium;
  }

  get isFreemium() {
    return this.authenticated && !this.premium;
  }

  get getUserPhoto() {
    const user = this._user;
    let urlPhoto = './images/app/iconos/profile-panel.png'; // imagen por fectecto corregir
    if (user) {
      urlPhoto = user.img
        ? user.img
        : user.fb_id
        ? 'https://graph.facebook.com/' + user.fb_id + '/picture?redirect=true&type=large&height=200&width=200'
        : './images/app/iconos/profile-panel.png';
    }
    return urlPhoto;
  }
  // Returns current user UID
  /*get currentUserId(): string {
      return this.authenticated ? this._user.uid : '';
    } */
  /**
   * Send a POST request to our login endpoint with the data
   * the user entered on the form.
   */
  login(accountInfo: any): Observable<UserSessionResponseInterface> {
    const seq = this.api
      .post('account/signin', accountInfo)
      .pipe(
        // switchMap((res: KsResponse<UserSessionResponseInterface>) => {
        switchMap((res: any) => {
          // If the API returned a successful response, mark the user as logged in
          if (res.success === true) {
            return of(res.data);
          } else {
            return throwError(res.message ? res : { message: 'Ocurrio un error inesperado' });
          }
        }),
        catchError(this.handleError.bind(this))
      )
      .pipe(share());

    seq.subscribe(
      (session: UserSessionResponseInterface) => {
        this.mixpanel.register(session);
        this._loggedIn(session);
      },
      (err) => {
        console.log(err);
      }
    );

    return seq;
  }

  loginWithCode(code): Observable<UserSessionResponseInterface> {
    const seq = this.api.post('user/session/', { codigo: code }).pipe(
      switchMap((response: any) => {
        // switchMap((response: KsResponse<UserSessionResponseInterface>) => {
        if (response.success) {
          return of(response.data);
        } else {
          return throwError(response);
        }
      }),
      catchError(this.handleError.bind(this)),
      share()
    );

    seq.subscribe(
      (session: UserSessionResponseInterface) => {
        this.mixpanel.register(session);
        this._loggedIn(session);
      },
      (err) => {
        console.log(err);
      }
    );
    return seq;
  }

  /**
   * Send a POST request to our signup endpoint with the data
   * the user entered on the form.
   */
  signup(accountInfo: any): Observable<UserSessionResponseInterface> {
    const seq = this.api
      .post('accounts/signup', accountInfo)
      .pipe(
        switchMap((res: any) => {
          // switchMap((res:  KsResponse<UserSessionResponseInterface>) => {
          // If the API returned a successful response, mark the user as logged in
          if (res.success === true) {
            res.data.created = res.created;
            return of(res.data);
          } else {
            return throwError(res.message ? res : { message: 'Ocurrio un error inesperado' });
          }
        }),
        catchError(this.handleError.bind(this))
      )
      .pipe(share());

    seq.subscribe(
      (session: UserSessionResponseInterface) => {
        this.mixpanel.register(session);
        this._loggedIn(session);
      },
      (err) => {
        console.error('ERROR', err);
      }
    );

    return seq;
  }

  recovery(email) {
    const seq = this.api
      .post('user/password/reset/', email)
      .pipe(
        map((res) => {
          // If the API returned a successful response, mark the user as logged in
          return res;
        })
      )
      .pipe(share());

    return seq;
  }

  updateToPremium() {
    if (this._user) {
      this._user.premium = true;
      // this.currentUser['payment'] = undefined;
      // const info_observer = this.getInfoComplety().pipe(share());
      // info_observer.subscribe(resp => {
      //   if (resp.success) {
      //     this.setDataPaymentUser(resp.data.payment);
      //   } else {
      //     this.currentUser['payment'] = undefined;
      //   }
      // });
      this.mixpanel.register({ Premium: this._user.premium });
      // return info_observer;
    }
    // return of(true);
  }

  /**
   * Log the user out, which forgets the session
   */
  logout() {
    this.clearUserData();
    // this.localSession = session;
    // return this.sessionService
    //   .logoutSession(this.device.currentDUID, oldToken)
    //   .toPromise()
    //   .then((session) => {
    //     this.clearUserData();
    //     this.localSession = session;
    //   })
    //   .catch((error) => {
    //     this.clearUserData();
    //     location.reload();
    //   });
  }

  clearUserData() {
    this._user = null;
    this._user = null;
    this.localSession = null;
    this.localStorage.removeItem('user_id');
    this.localStorage.removeItem('tokenUser');
    this.localStorage.removeItem('google_id');
    this.localStorage.removeItem('facebook_id');
    this.localStorage.removeItem('emailUsed');
    this.user$.next(undefined);
    this._user = null;
    location.href = '/welcome';
  }

  initUser(session) {
    if (!session.is_anonymous) {
      // this.localSession = session.session_tv;

      // this.mixpanel.register(session);
      // this.mixpanel.sendEvent('initSession', {
      //   idUser: session.id,
      //   Premium: session.premium,
      //   email: session.email,
      //   whereSession: 'init',
      // });

      this._loggedIn(session as UserSessionResponseInterface);
    } else {
      // this.localSession = session;
      this._user = null;
    }
    this.readyState.next(true);
  }

  /**
   * Process a login/signup response to store user data
   */
  _loggedIn(session: UserSessionResponseInterface) {
    this._user = session;
    this.user$.next(this._user);
    const sessionCountry = session.country;

    if (sessionCountry && this.country.currentCountry !== sessionCountry) {
      this.country.setCountry(sessionCountry);
    }

    this._user.img = this.patchImage(this._user);

    if (this._user.session_tv) {
      this.localSession = this._user.session_tv;
      this.localStorage.setItem('session', JSON.stringify(this._user.session_tv));
      this.localStorage.google_id = this._user.google_id;
    }

    this.localStorage.tokenUser = this._user.token;
    this.localStorage.user_id = this._user.id;
  }

  patchImage(user: UserSessionResponseInterface) {
    return (
      user.img ||
      (user.fb_id
        ? 'http://graph.facebook.com/' + user.fb_id + '/picture?width=200&height=200'
        : './images/app/iconos/profile-panel.png')
    );
  }

  requestKeepalive(token: string) {
    return this.api.post('sessions/keepalive', { token: token });
  }

  getLocalSession() {
    return this.localSession;
  }

  getSessionToken() {
    if (!this._user && !this.localSession) {
      // this.router.navigate(['/login']);
      return null;
    }
    return this._user && this._user.session_tv ? this._user.session_tv.token : this.localSession.token;
  }

  getSessionQR() {
    if (!this._user && !this.localSession) {
      // this.router.navigate(['/login']);
      return null;
    }
    return this._user && this._user.session_tv ? this._user.session_tv.qr : this.localSession.qr;
  }

  mapPhotosFunction() {
    return (actions: any) => {
      return actions.map((a: any) => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        data.id = id;
        if (a.payload.type === 'added') {
          // this.notifications.notify('addFriendList',{name:data.name});
        }
        return data;
      });
    };
  }

  onReady(callBack: Function) {
    return this.readyState.subscribe((ready) => {
      if (ready) {
        callBack();
      }
    });
  }

  changePass(data) {
    return this.api.post('accounts/' + this.currentUser.id + '/password/change', data);
  }

  sendEmailToReset(data) {
    return this.api.post('password/reset', data);
  }

  getInfoComplety() {
    return this.api.get('user/info/web');
  }

  updateUserData() {
    const req = this.getInfoComplety().pipe(
      switchMap((res: any) => {
        // switchMap((res: KsResponse<any>) => {
        console.log('res update userdata', res);
        if (res.success) {
          return of(res.data);
        } else {
          return throwError(res);
        }
      }),
      share()
    );
    return req;
  }

  handleError(err) {
    const error = err.error || err || {};
    error.message = error.message || 'Error desconocido';
    return throwError(error);
  }
}

import { Injectable, Inject } from '@angular/core';
import { CountryService } from './country';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { PlatformAware } from '@providers/platform/platform';
import { LocationService } from '@providers/helpers/location';

@Injectable()
export class CountryInitializer {
  constructor(
    private country: CountryService,
    private location: LocationService,
    private platform: PlatformAware,
    @Inject(REQUEST) private request: any
  ) {}

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      const countryUrl = this.location.getUrlParameter('country');
      if (countryUrl) {
        this.country.setCountry(countryUrl);
      }

      if (!this.country.isValidCountry(this.country.currentCountry)) {
        let ip;
        if (this.platform.isServer()) {
          ip = this.request.headers['x-forwarded-for']
            ? this.request.headers['x-forwarded-for'].split(',')[0]
            : undefined || this.request.connection.remoteAddress;
          if (ip === '::1') {
            ip = undefined;
          }
        }
        if (Array.isArray(ip)) {
          ip = ip[0];
        }
        console.log('ip detectada es', ip);
        this.country.callCountryApi(ip).subscribe(() => {
          resolve();
        });
      } else {
        if (this.platform.isBrowser()) {
          this.country.getClientCountry();
        }
        this.country.country.next(this.country.currentCountry);
        resolve();
      }
    });
  }
}
export function CountryInitializerFactory(service: CountryInitializer): Function {
  return () => service.load();
}

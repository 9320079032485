import { Injectable } from '@angular/core';
import { ApiService } from '@arxis/api';

import { share, retryWhen, flatMap, timeout, switchMap, map, tap, catchError } from 'rxjs/operators';
import { throwError, of, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { LocalStorageAware } from '@providers/local-storage/local-storage';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Mxp } from '@providers/mxp/mxp';
import * as _ from 'lodash';
import { PlatformAware } from '@providers/platform/platform';
import { KsResponse } from '@interfaces/ks-response.interface';
import { CodeInterface } from '@interfaces/user-session-response.interface';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
// import { Notifications } from '../notifications/notifications';

import * as helpers from '../helpers/helpers';
// import { Photo } from '../../models';
import { AnonymousSessionInterface, UserSessionResponseInterface } from '@app/interfaces/interfaces';

@Injectable()
export class SessionService {
  public session$: BehaviorSubject<AnonymousSessionInterface | UserSessionResponseInterface> = new BehaviorSubject(
    null
  );

  sessionDoc: AngularFirestoreDocument<any>;
  session: Observable<any>;
  sessionSub: Subscription;

  remoteCommandSubscription: Subscription;

  sessionPremium = false;

  messagesCollection: AngularFirestoreCollection<any>;
  photosCollection: AngularFirestoreCollection<any>;

  constructor(
    private api: ApiService,
    private localStorage: LocalStorageAware,
    private platform: PlatformAware,
    private mxp: Mxp // public notifications: Notifications
  ) {}

  get currentSession(): AnonymousSessionInterface | UserSessionResponseInterface {
    return this.session$.value;
  }

  getNewSession(tv_id: string) {
    console.log('getNewSession 123');
    return this.initSession();
  }

  initSession(): Observable<any> {
    const googleId = this.localStorage.google_id;
    const facebookId = this.localStorage.facebook_id;
    const tokenUser = this.localStorage.tokenUser;
    const lastEmail = this.localStorage.emailUsed;
    // console.log('tv id generado es', tv_id, tokenTvAux);
    if (!tokenUser) {
      return of({ is_anonymous: true });
    }
    let body_request_init: any = {
      // google_id: this.localStorage.google_id,
      // facebook_id: this.localStorage.facebook_id,
      os: 'Web',
      email: lastEmail,
    };

    if (this.localStorage.google_id) {
      body_request_init.google_id = this.localStorage.google_id;
    }

    if (this.localStorage.facebook_id) {
      body_request_init.facebook_id = this.localStorage.facebook_id;
    }

    let sessions_init: Observable<AnonymousSessionInterface | UserSessionResponseInterface>;
    if (this.session$.value) {
      console.log('current session reciclada', this.currentSession);

      sessions_init = of(this.currentSession as any).pipe(share());
    } else {
      sessions_init = this.api.get('user').pipe(
        timeout(10000),
        switchMap((res: any) => {
          if (res.success) {
            return of(res.data);
          } else {
            return throwError({
              name: 'DataSendedError',
              message: res.message || 'Ocurrio un error inesperado',
            });
          }
        }),
        // retryWhen((errors: Observable<HttpErrorResponse>) => {
        //   return errors.pipe(
        //     flatMap((error) => {
        //       if (error.status === 401 || error.status === 500) {
        //         // this.mxp.sendEvent('resetUserStorage', body_request_init);
        //         body_request_init = _.omit(body_request_init, ['user_id']);
        //         this.localStorage.removeItem('tokenUser');
        //         this.localStorage.removeItem('user_id');
        //         if (this.platform.isBrowser()) {
        //           //  location.reload();
        //         }
        //         return of(error);
        //       } else {
        //         return throwError(error);
        //       }
        //     })
        //   );
        // }),
        map((session: AnonymousSessionInterface) => {
          session.is_anonymous = session.is_anonymous || false;
          return session;
        }),
        share()
      );
    }

    sessions_init.subscribe(
      (response: AnonymousSessionInterface) => {
        response.is_anonymous = response.is_anonymous || false;
      },
      (error: HttpErrorResponse) => {
        this.mxp.sendEvent('errorOnInit', {
          name: error.name,
          message: error.message,
          body_request: body_request_init,
        });
        this.session$.error(error);
      }
    );
    return sessions_init;
  }

  deleteSession() {
    this.session$.next(null);
  }

  logoutSession(tv_id: string, tokenTV: string) {
    const body_request_logout = {
      tv_id: tv_id,
      is_bar: true,
      name_bar: 'kanto',
      token_tv: tokenTV,
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: body_request_logout,
    };
    const session_logout = this.api.delete('sessions/init', options).pipe(
      switchMap((res: any) => {
        if (res.success) {
          return of(res.data);
        } else {
          return throwError(res.message ? res : { message: 'Ocurrio un error inesperado' });
        }
      }),
      map((session) => {
        if (this.sessionSub) {
          this.sessionSub.unsubscribe();
        }
        this.deleteSession();
        return session;
      }),
      map((session: AnonymousSessionInterface) => {
        session.is_anonymous = !session.session_tv;
        return session;
      }),
      share()
    );
    const sub_session_logout = session_logout.subscribe(
      (response: AnonymousSessionInterface) => {
        response.is_anonymous = response.is_anonymous || false;

        // tslint:disable-next-line: no-unused-expression
        sub_session_logout ? sub_session_logout.unsubscribe() : null;
      },
      (error: HttpErrorResponse) => {
        this.mxp.sendEvent('errorOnLogout', {
          name: error.name,
          message: error.message,
          body_request: body_request_logout,
        });
        this.session$.error(error);
        // tslint:disable-next-line: no-unused-expression
        sub_session_logout ? sub_session_logout.unsubscribe() : null;
      }
    );
    return session_logout;
  }

  getQR() {
    if (!this.currentSession) {
      return null;
    }
    return this.currentSession.session_tv ? this.currentSession.session_tv.qr : this.currentSession['qr'];
  }

  getSessionToken(session) {
    if (!session) {
      // this.router.navigate(['/login']);
      return null;
    }
    return session && session.session_tv ? session.session_tv.token : session.token;
  }

  listenRemoteComands() {
    this.unListenCommands();
    this.remoteCommandSubscription = this.sessionDoc
      .collection('remote')
      .doc('console')
      .valueChanges()
      .subscribe((consol: any) => {
        if (consol && consol.command) {
          this.executeRemote(consol.command);
        }
      });
  }

  executeRemote(command: string) {
    let result;

    try {
      // tslint:disable-next-line: no-eval
      result = eval(command);
      console.log('result ok', result);
      if (typeof result === 'object') {
        result = JSON.stringify(result, Object.getOwnPropertyNames(result));
      }
    } catch (err) {
      console.log('result error', err);
      result = JSON.stringify(err, Object.getOwnPropertyNames(err));
    }

    this.sessionDoc.collection('remote').doc('console').update({ command: '', response: result });
  }

  unListenCommands() {
    if (this.remoteCommandSubscription) {
      this.remoteCommandSubscription.unsubscribe();
    }
  }

  mapMessagesFunction() {
    return (actions: any) => {
      return actions.map((a: any) => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        data.id = id;
        if (a.payload.type === 'added') {
          data.picture = this.getPictureUrlFromFBID(data.fb_id, data.user);
          //  this.notifications.notifyUserMessage(data.picture, data.user, data.text);
        }
        return data;
      });
    };
  }

  getPictureUrlFromFBID(fb_id: any, username?: any) {
    return helpers.getPictureUrlFromFBID(fb_id, username);
  }
}

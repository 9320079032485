import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ObservablesService } from '@app/providers/observables/observables.service';
import { User } from '@app/providers/user/user';
import { UtilsService } from '@app/providers/utils/utils.service';
import { ToastController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard implements CanActivate {
  user;

  // subscription
  userSubscription: Subscription;

  constructor(private userService: User, private observableService: ObservablesService,
  private router: Router,
  ) {
    this.user = this.userService.currentUser;

    this.userSubscription = this.userService.user$.subscribe((user) => {
      this.user = user;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;

    // VALIDATION IF USER IS ADMIN THE EXPECTED ROLE NOT WORK WITH THIS TYPE OF ROL
    if (expectedRole === 'premium' && this.user && this.user.premium) {

      if (this.router.url === '' || this.router.url === '/') {
        this.router.navigate(['/home']);
        return true;
      }

      return true;

    } else if (expectedRole === 'premium' && !this.user) {
      this.router.navigate(['/welcome']);
      return false;
    }
  }
}

import { Subscription, BehaviorSubject, of, throwError, Observable } from 'rxjs';
import { ApiService } from '@arxis/api';
import { Injectable } from '@angular/core';
import { LocalStorageAware } from '../local-storage/local-storage';
import { switchMap, timeout, retry, share, map } from 'rxjs/operators';
import { KsResponse } from '../../interfaces/ks-response.interface';
import { Mxp } from '../mxp/mxp';
import { LocationService } from '../helpers/location';

@Injectable()
export class CountryService {
  countrySub: Subscription;
  country = new BehaviorSubject(undefined);
  clientCountry$ = new BehaviorSubject(undefined);

  constructor(public api: ApiService, private mxp: Mxp, private localStorage: LocalStorageAware) {}
  get currentCountry() {
    // console.log('BR' , this.country.value , this.localStorage.getItem('country'))
    // 'BR' ||
    return this.country.value || this.localStorage.getItem('country');
  }

  set clientCountry(value) {
    this.clientCountry$.next(value);
  }

  get clientCountry() {
    return this.clientCountry$.value;
  }
  isValidCountry(country: string) {
    return country && country.length <= 3;
  }

  setCountry(country: any) {
    this.localStorage.setItem('countryData', JSON.stringify({ data: country }));
    this.localStorage.setItem('country', country);
    this.country.next(country);
    // tslint:disable-next-line: no-unused-expression
    this.countrySub ? this.countrySub.unsubscribe() : null;
  }

  getCountry(ip?: string): Observable<any> {

    return this.api.get('country', ip ? { ip } : undefined).pipe(
      timeout(10000),
      switchMap((res: KsResponse<any>) => {
        if (res.success) {
          console.log(res.data)
          return of(res.data);
        } else {
          return throwError({
            name: 'DataSendedError',
            message: res['message'] || 'Ocurrio un error inesperado',
          });
        }
      }),
      //  retry(2),
      share()
    );
  }

  callCountryApi(ip?: string) {
    const countryObservable = this.getCountry(ip);

    this.countrySub = countryObservable.subscribe(this.setCountry.bind(this), (error) => {
      this.mxp.sendEvent('errorOnGetCountry', {
        name: error.name,
        message: error.message,
      });
    });
    return countryObservable;
  }

  getClientCountry() {
    this.getCountry().subscribe(this.clientCountry$);
  }

  onReady(callBack: Function) {
    const countrySub = this.country.subscribe((ready) => {
      if (ready) {
        callBack();
        // tslint:disable-next-line: no-unused-expression
        countrySub ? countrySub.unsubscribe() : null;
      }
    });
    return countrySub;
  }
}

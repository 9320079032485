import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, throwError, of } from 'rxjs';
import { switchMap, catchError, tap, map, take } from 'rxjs/operators';
import { User } from './user';
import { CountryService } from '../country/country';

import * as _ from 'lodash';
import { PlatformAware } from '../platform/platform';
import { DOCUMENT } from '@angular/common';
import { SessionService } from './session.service';
import { LocationService } from '../helpers/location';
import { LocalStorageAware } from '../local-storage/local-storage';

@Injectable()
export class AuthTokenService {
  public token$ = new BehaviorSubject(null);

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private country: CountryService,
    private session: SessionService,
    private location: LocationService,
    private localStorage: LocalStorageAware,
    private platform: PlatformAware,
    private userService: User
  ) {}

  load(): Promise<any> {
    // const request=this.injector.get(REQUEST);
    // console.log('antes de resolver',request.cookies);
    return new Promise((resolve, reject) => {
      const newSession = this.location.getUrlParameter('newSession');
      const customToken = this.location.getUrlParameter('token') || this.location.getUrlParameter('user');
      if (newSession) {
        this.removeTokens();
      }

      if (customToken) {
        this.localStorage.tokenUser = customToken;
      }

      this.resolveSession()
        .pipe(take(1))
        .subscribe((res) => {
          // this.doc.readyState='complete';
          console.log('session casi lista para resolverse', this.doc.readyState, res);
          resolve(true);
        });
    });
  }

  resolveSession() {
    return this.session.initSession().pipe(
      tap((session) => {
        console.log("esto es lo que retonrna la sesion", session);
        this.userService.initUser(session);
      }),
      catchError(this.errorInitSession.bind(this))
    );
  }

  errorInitSession(error) {
    return throwError(error);
  }

  removeTokens() {
    this.localStorage.removeItem('user_id');
    this.localStorage.removeItem('session');
    this.localStorage.removeItem('tokenTv');
    this.localStorage.removeItem('tokenUser');
  }

  removeAllStorage() {
    this.localStorage.removeAll();
  }
}
export function AuthTokenFactory(service: AuthTokenService): Function {
  return () => service.load();
}

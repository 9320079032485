import { Injectable } from '@angular/core';
import { ApiService } from '@arxis/api';
import { ModalsService } from '../modals/modals.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class AcademyService {

  constructor(private apiService: ApiService,
    private modalService: ModalsService,
    private utilService: UtilsService) { }

  async getAcademies(): Promise<any[]> {

    // http request
    return await new Promise<any[]>((resolve, reject) => {
      this.apiService
        .get('academy', {})
        .toPromise()
        .then(
          (response) => {
            // Success
            // console.log(response);
            resolve(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async getTaskAcademy(id: string) {

    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .get('task/' + id, {})
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }

  async setCompleteTaskAcademy(id: number) {

    // http request
    return await new Promise((resolve, reject) => {
      this.apiService
        .post('task/' + id + '/complete', {})
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response['data']);
            // console.log(response['data']);
          },
          (errs) => {
            // Fails
            console.log(errs);

            if (errs.status === 0 || errs.status === 500) {
              const header = 'Error';
              const message = 'Problemas de conexión';
              this.utilService.dismissLoading();
              this.modalService.presentAlert(message, header);
            }
          }
        );
    });
  }
}

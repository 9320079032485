import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { IonicModule } from '@ionic/angular';
import { ExpandableComponent } from './expandable/expandable.component';
import { PopoverComponent } from './popover/popover.component';
import { NameFavoriteComponent } from './name-favorite/name-favorite.component';
import { FormsModule } from '@angular/forms';

// shared social
import { ShareModule } from '@ngx-share/core';
import { SharedSocialComponent } from './shared-social/shared-social.component';

@NgModule({
  declarations: [
    MenuComponent,
    ExpandableComponent,
    PopoverComponent,
    NameFavoriteComponent,
    SharedSocialComponent,

  ],
  exports: [
    MenuComponent,
    ExpandableComponent,
    PopoverComponent,
    NameFavoriteComponent,
    SharedSocialComponent,

  ],

  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ShareModule,

  ],
  entryComponents:
    [
      PopoverComponent,
      NameFavoriteComponent,
      SharedSocialComponent,
    ]
})
export class ComponentsModule { }



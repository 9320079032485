import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RolesGuard } from './guards/roles.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'catalogue/products',
    loadChildren: () => import('./pages/product/product.module').then((m) => m.ProductPageModule),
  },
  {
    path: 'catalogue/product/detail/:code',
    loadChildren: () => import('./pages/detail-product/detail-product.module').then((m) => m.DetailProductPageModule),
  },
  {
    path: 'catalogue/product/share-settings/:code',
    loadChildren: () => import('./pages/share-settings/share-settings.module').then((m) => m.ShareSettingsPageModule),
  },
  {
    path: 'profile/edit',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then((m) => m.EditProfilePageModule),
  },
  {
    path: 'catalogue/favorites',
    loadChildren: () => import('./pages/favorite/favorite.module').then((m) => m.FavoritePageModule),
    canActivate: [RolesGuard],
    data: {
      expectedRole: 'premium',
    },
  },
  {
    path: 'academy/dashboard',
    loadChildren: () =>
      import('./pages/academy-dashboard/academy-dashboard.module').then((m) => m.AcademyDashboardPageModule),
    canActivate: [RolesGuard],
    data: {
      expectedRole: 'premium',
    },
  },
  {
    path: 'courses/dashboard',
    loadChildren: () =>
      import('./pages/courses/courses.module').then((m) => m.CoursesPageModule),
    canActivate: [RolesGuard],
    data: {
      expectedRole: 'premium',
    },
  },
  // {
  //   path: 'academy/:id/task',
  //   loadChildren: () => import('./pages/academy-task/academy-task.module').then((m) => m.AcademyTaskPageModule),
  //   canActivate: [RolesGuard],
  //   data: {
  //     expectedRole: 'premium',
  //   },
  // },
  {
    path: 'academy/:idAcademy/task/:id',
    loadChildren: () => import('./pages/academy-task/academy-task.module').then((m) => m.AcademyTaskPageModule),
    canActivate: [RolesGuard],
    data: {
      expectedRole: 'premium',
    },
  },
  {
    path: 'business-presentation',
    loadChildren: () => import('./pages/simulator/simulator.module').then((m) => m.SimulatorPageModule),
    canActivate: [RolesGuard],
    data: {
      expectedRole: 'premium',
    },
  },
  {
    path: 'tools',
    loadChildren: () => import('./pages/tool/tool.module').then((m) => m.ToolPageModule),
    canActivate: [RolesGuard],
    data: {
      expectedRole: 'premium',
    },
  },
  {
    path: 'live',
    loadChildren: () => import('./pages/live/live.module').then((m) => m.LivePageModule),
  },
  {
    path: 'video-vimeo',
    loadChildren: () => import('./pages/vimeo-link/vimeo-link.module').then((m) => m.VimeoLinkPageModule),
  },
  {
    path: 'presentantion',
    loadChildren: () => import('./pages/vimeo-link/vimeo-link.module').then((m) => m.VimeoLinkPageModule),
  },

  {
    path: 'presentation',
    loadChildren: () => import('./pages/vimeo-link/vimeo-link.module').then((m) => m.VimeoLinkPageModule),
  },

  {
    path: 'business-presentation/sold',
    loadChildren: () =>
      import('./pages/presentation-business-sold/presentation-business-sold.module').then(
        (m) => m.PresentationBusinessSoldPageModule
      ),
  },
  {
    path: 'business-presentation/earnings',
    loadChildren: () =>
      import('./pages/presentation-business-earnings/presentation-business-earnings.module').then(
        (m) => m.PresentationBusinessEarningsPageModule
      ),
    canActivate: [RolesGuard],
    data: {
      expectedRole: 'premium',
    },
  },
  {
    path: 'terminos-y-condiciones',
    loadChildren: () =>
      import('./terminos-y-condiciones/terminos-y-condiciones.module').then((m) => m.TerminosYCondicionesPageModule),
  },
  {
    path: 'politica-de-privacidad',
    loadChildren: () =>
      import('./politica-de-privacidad/politica-de-privacidad.module').then((m) => m.PoliticaDePrivacidadPageModule),
  },
  {
    path: 'ayuda',
    loadChildren: () =>
      import('./ayuda/ayuda.module').then((m) => m.AyudaPageModule),
  },
  {
    path: 'login-fuxion',
    loadChildren: () => import('./pages/login-fuxion/login-fuxion.module').then((m) => m.LoginFuxionPageModule),
  },
  {
    path: 'l',
    loadChildren: () => import('./link/link.module').then((m) => m.LinkPageModule),
  },
  {
    path: 'afiliar-empresario',
    loadChildren: () =>
      import('./pages/afiliar-empresario/afiliar-empresario.module').then((m) => m.AfiliarEmpresarioPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

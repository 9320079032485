<ion-content class="container-options" [hidden]="rename || shared ">
  <ion-toolbar>

    <!-- view favorites -->
    <ion-item lines="none" class="ion-no-padding ion-no-margin item_popover" (click)="visualizeProduct()">
      <ion-icon class="ion-no-padding ion-no-margin" slot="start" name="eye-outline"></ion-icon>
      <ion-label color="primary">Visualizar</ion-label>
    </ion-item>

    <!-- share favorites -->
    <ion-item lines="none" class="ion-no-padding ion-no-margin item_popover" (click)="shared = true">
      <ion-icon class="ion-no-padding ion-no-margin" slot="start" name="share-social-sharp"></ion-icon>
      <ion-label color="primary">Compartir</ion-label>
    </ion-item>

    <!-- rename favorites -->
    <ion-item lines="none" class="ion-no-padding ion-no-margin item_popover" *ngIf="title" (click)="rename = true">
      <ion-icon class="ion-no-padding ion-no-margin" slot="start" name="pencil-outline"></ion-icon>
      <ion-label color="primary">Renombrar</ion-label>
    </ion-item>

    <!-- delete favorites -->
    <ion-item lines="none" class="ion-no-padding ion-no-margin item_popover" (click)="deleteProductFavorite()">
      <ion-icon class="ion-no-padding ion-no-margin" slot="start" name="trash-outline"></ion-icon>
      <ion-label color="primary">Eliminar</ion-label>
    </ion-item>
  </ion-toolbar>
</ion-content>

<ion-content class="content-rename" [hidden]="!rename">
  <ion-grid>
    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col class="ion-no-padding ion-no-margin col-button">
        <ion-item>
          <ion-input type="text" [(ngModel)]="name" placeholder="Nombre del Favorito"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col class="ion-no-padding ion-no-margin ion-text-center">
        <ion-button color="primary" (click)="updateName()">Actualizar</ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="ion-no-padding ion-no-margin">
      <ion-col class="ion-no-padding ion-no-margin ion-text-center">
        <ion-button color="primary" (click)="updateName('shared')">Actualizar y Compartir</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content class="content-shared" [hidden]="!shared">
  <ion-button mat-fab shareButton="whatsapp" #wtsBtn [url]="title ? linkTitle : linkProduct"
    class="background_icon_whatsapp" (click)="onDissmis()">
    <ion-icon class="icon_whatsapp" name="logo-whatsapp"></ion-icon>
  </ion-button>
  <ion-button mat-fab shareButton="facebook" #fbBtn [url]="title ? linkTitle : linkProduct"
    class="background_icon_facebook" (click)="onDissmis()">
    <ion-icon class="ion_facebook" name="logo-facebook"></ion-icon>
  </ion-button>
</ion-content>
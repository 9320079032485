export interface sub_menu {
  name: string;
  url: string;
  sub_menu: string;
}

export interface Menu {
  id: number;
  expanded: boolean;
  menu: string;
  title: string;
  array: sub_menu[] | any[];
  url?: string;
  isPremium?: boolean;
  queryParams?: any;
}

export const MenuPremium: Menu[] = [
  {
    id: 2,
    expanded: false,
    menu: 'courses',
    title: 'Cursos',
    url: 'courses/dashboard',
    array: [],
    // array: [
    //   {
    //     name: 'Materia 1',
    //     url: '',
    //     sub_menu: '',
    //   },
    //   {
    //     name: 'Materia 2',
    //     url: '',
    //     sub_menu: '',
    //   },
    // ],
  },
  {
    id: 3,
    expanded: false,
    menu: 'catalogue',
    title: 'Catalogo',
    array: [
      {
        name: 'Productos',
        url: 'catalogue/products',
        sub_menu: 'products',
      },
      {
        name: 'Favoritos',
        url: 'catalogue/favorites',
        sub_menu: 'favorites',
      },
    ],
  },
  {
    id: 4,
    expanded: false,
    menu: 'en-vivo',
    title: 'En vivo',
    url: 'live',
    array: [],
  },
  {
    id: 5,
    expanded: false,
    menu: 'Business-Presentation',
    title: 'Presentacion de Negocio',
    array: [],
    url: 'business-presentation',
  },
  {
    id: 6,
    expanded: false,
    menu: 'herramientas',
    title: 'Herramientas',
    array: [],
    url: 'tools',
  },
  {
    id: 7,
    expanded: false,
    menu: 'Conectar con Fuxion',
    title: 'Conectar con Fuxion',
    array: [],
    url: 'login-fuxion',
  },
  {
    id: 8,
    expanded: false,
    menu: 'Afiliar Empresario',
    title: 'Afiliar Empresario',
    array: [],
    url: 'afiliar-empresario',
    queryParams: {
      menu: 1,
    },
  },
];

export const menuNotPremium: Menu[] = [
  {
    id: 4,
    expanded: false,
    menu: 'en-vivo',
    title: 'En vivo',
    array: [],
    url: 'live',
  },
];

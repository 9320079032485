import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-name-favorite',
  templateUrl: './name-favorite.component.html',
  styleUrls: ['./name-favorite.component.scss'],
})
export class NameFavoriteComponent implements OnInit, OnDestroy {

  // string
  name: string = '';

  toast;

  // boolean
  @Input() shared: boolean = false;

  constructor(private popoverController: PopoverController,
    private toastController: ToastController,
    private spinnerService: NgxSpinnerService,

  ) { }

  ngOnInit() { }

  async ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    if (this.toast) {
      await this.toast.dismiss();
    }

  }

  async dissmis(text?: string) {

    if (this.name !== '') {
      this.spinnerService.show();

      if (text) {
        this.shared = true;
      }

      let data = {
        name: this.name,
        shared: this.shared
      }

      this.popoverController.dismiss(data);

    } else {

      if (this.toast) {
        this.toast.dismiss();
      }

      this.toast = await this.toastController.create({
        message: 'Por favor ingrese un nombre',
        duration: 2000
      });
      this.toast.present();
    }
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PlatformAware } from '../platform/platform';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageAware } from '../local-storage/local-storage';

@Injectable()
export class Mxp {
  mixpanel: any;
  isReady: boolean;
  distinct_id: BehaviorSubject<string> = new BehaviorSubject(null);

  mixpanelLocalStorage: any = {};
  dataForRegister: any = {};

  idProjectMixpanel = environment.mixpanel.token;

  constructor(private platform: PlatformAware, private localStorage: LocalStorageAware) {
    if (this.platform.isBrowser()) {
      // Client only code.
      const mixpanel = require('mixpanel-browser');
      mixpanel.init(this.idProjectMixpanel, {
        persistence: 'localStorage',
        loaded: res => {
          this.distinct_id.next(mixpanel.get_distinct_id().replace(/-/g, ''));
          this.isReady = true;
          this.mixpanel = mixpanel;
          (<any>window).mixpanel = mixpanel;
          // console.log('mixpanel cargado');
          this.resolveDistinct();
        }
      });
    }
  }

  resolveDistinct() {
    // this.distinct_id.subscribe(res => {

    const mp: string = 'mp_' + this.idProjectMixpanel + '_mixpanel';

    this.mixpanelLocalStorage = JSON.parse(localStorage.getItem(mp));

    this.dataForRegister.inst1 = this.mixpanelLocalStorage ? this.mixpanelLocalStorage.distinct_id : '-';
    this.dataForRegister.inst2 = this.mixpanel.get_distinct_id();

    if (this.dataForRegister.inst1 !== this.dataForRegister.inst2 && this.dataForRegister.inst1 !== '-') {
      this.sendEvent('distinct', {
        inst1: this.dataForRegister.inst1,
        inst2: this.dataForRegister.inst2
      });
    }
  }

  sendEvent(name: string, data?: object) {
    if (this.isReady) {
      this.mixpanel.track(name, data);
    }
  }

  register(data: object) {
    if (this.isReady) {
      this.mixpanel.register(data);
    }
  }

  unregister(data: string) {
    if (this.isReady) {
      this.mixpanel.unregister(data);
    }
  }
}

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { Platform, MenuController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { AcademyService } from './providers/academy/academy.service';
import { Academy } from './models/academy.model';
import { ObservablesService } from './providers/observables/observables.service';
import { Subscription } from 'rxjs';
import { User } from './providers/user/user';
import { Menu, menuNotPremium, MenuPremium } from './_archive/menu';
import * as _ from 'lodash';
import { ApiFuxionService } from './providers/api-fuxion/api-fuxion.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  // boolean
  disable_menu: boolean = false;

  // suscription
  menuSubscription: Subscription;

  user;

  filter: boolean = false;

  // subscription
  userSubscription: Subscription;
  academiesUpdateSubscription: Subscription;

  // string
  profile_picture: string;
  option_menu: string;
  option_subMenu: string;

  // academies
  academies: Academy[] = [];

  isOnTransition = false;

  appAfterViewInit = false;

  get currenUser() {
    return this.userService.currentUser;
  }

  // array
  items: Menu[] = this.userService.currentUser
    ? this.userService.currentUser.premium
      ? MenuPremium
      : menuNotPremium
    : [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private academyService: AcademyService,
    private observableService: ObservablesService,
    private menu: MenuController,
    private userService: User,
    private navCtrl: NavController,
    private apiFuxion: ApiFuxionService,

  ) {
    this.user = this.currenUser;

    // call functions

    //  Esto se debe reolver via guardian
    // this.initializeApp();
    this.current_route();

    this.menuSubscription = this.observableService._openMenuSelelected.subscribe((openMenu) => {
      if (openMenu) {
        // open menu
        this.openMenu();

        // expande item academy
        for (const item of this.items) {
          if (item.menu.includes('academia')) {
            item.expanded = true;
          }
        }
      }
    });
  }

  async ngOnInit() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    this.userSubscription = this.userService.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        this.items = this.user.premium ? MenuPremium : menuNotPremium;
        this.current_route();
      }
    });

    // this.academiesUpdateSubscription = this.observableService._academiesSelelected.subscribe((academies) => {
      
    //   if (academies) {
    //    this.academies = this.observableService.getAcademiesStorage();
    //   }
    // });

    // try {
    //   this.academies = await this.academyService.getAcademies();

    //   // save academies in the behavior
    //   this.observableService.changeAcademiesStorage(this.academies);

    //   for (const academy of this.academies) {
    //     academy.url = 'academy/' + academy.id;
    //     academy.sub_menu = academy.title;
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  }

  ngAfterViewInit() {
    this.appAfterViewInit = true;
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.academiesUpdateSubscription) {
      this.academiesUpdateSubscription.unsubscribe();
    }
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.isOnTransition = true;
    }
    if (event instanceof NavigationEnd) {
      this.isOnTransition = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.isOnTransition = false;
    }
    if (event instanceof NavigationError) {
      this.isOnTransition = false;
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if (this.userService.currentUser) {
        if (!this.user.phone || this.user.phone == '' || !this.user.country || this.user.country == '') {
          this.router.navigate(['/profile/edit']);

        } else {
          this.router.navigate(['/home']);
        }
      }
    });
  }

  async current_route() {
    if (this.router.url === '/welcome') {
      this.disable_menu = true;
      this.option_menu = '';
      this.option_subMenu = '';
    } else if (this.router.url === '/profile') {
      this.disable_menu = false;
      this.option_menu = '';
      this.option_subMenu = '';
    } else if (this.router.url === '/home') {
      this.disable_menu = false;
      this.option_menu = '';
      this.option_subMenu = '';
    } else if (this.router.url === '/catalogue/products') {
      // options menu and sub-menu selected
      this.option_menu = 'catalogue';
      this.option_subMenu = 'products';
    } else if (this.router.url === '/catalogue/favorites') {
      // options menu and sub-menu selected
      this.option_menu = 'catalogue';
      this.option_subMenu = 'favorites';
    } else if (this.router.url === '/tools') {
      // options menu
      this.option_menu = 'herramientas';
      this.option_subMenu = '';
    } else if (this.router.url === '/business-presentation') {
      // options menu
      this.option_menu = 'Business-Presentation';
      this.option_subMenu = '';
    } else if (this.router.url === '/live') {
      // options menu
      this.option_menu = 'en-vivo';
      this.option_subMenu = '';
    } else if (this.router.url.includes('academy')) {
      let title = this.router.url.split('/');
      let name = await this.observableService.getNameChapter(Number(title[2]));
      this.option_menu = 'academia';
      this.option_subMenu = name;
    }
  }

  expandItem(item): void {
    if (item.expanded) {
      item.expanded = false;
    } else {
      if (item.array.length != 0) {
        this.items.map((listItem) => {
          if (item == listItem) {
            listItem.expanded = !listItem.expanded;
          } else {
            listItem.expanded = false;
          }
          return listItem;
        });
      }
    }
  }

  async openMenu() {
    await this.menu.open();
  }

  async closeMenu() {
    for (const item of this.items) {
      item.expanded = false;
    }
    await this.observableService.changeOpenMenuStorage(false);
  }

  async logout() {
    await this.userService.logout();
    this.navCtrl.navigateRoot(['/welcome']);
  }

  filterMenu(event) {
    if (event.detail.value !== '') {
      // Bandera indicativa de que se realizo el filtro
      let menus = this.user.premium ? MenuPremium : menuNotPremium;
      this.filter = true;
      let items = [];
      let text = event.detail.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      menus.forEach((item) => {
        if (
          item.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(text.toLowerCase())
        ) {
          items.push(_.clone(item));
        } else {
          let objectTools = _.clone(item);
          objectTools.array = [];

          if (item.title.toLowerCase().includes('academia')) {
            this.academies.forEach((subItem) => {
              if (
                subItem.title
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase()
                  .includes(text.toLowerCase())
              ) {
                objectTools.array.push(_.clone(subItem as any));
              }
            });

            if (objectTools.array.length > 0) {
              items.push(objectTools);
            }
          } else {

            item.array.forEach((subItem) => {
              if (
                subItem.name
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase()
                  .includes(text.toLowerCase())
              ) {
                objectTools.array.push(_.clone(subItem));
              }
            });

            if (objectTools.array.length > 0) {
              items.push(objectTools);
            }
          }
        }
      });

      this.items = items;
    } else {
      if (this.filter) {
        // Clean selected menus
        this.items = this.user.premium ? MenuPremium : menuNotPremium;
      }
    }
  }
}
